import './index.css';
import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios'
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import ShowList from "./showlist"
import MenuBar from './MenuBar'
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';
import HeroUnit from "./HeroUnit"
import ShowCard from "./ShowCard";
import DoubleLine from "./DoubleLine";
import CaveatFooter from "./CaveatFooter"
import ReactMarkdown from 'react-markdown'
import ReactGA from "react-ga4"
import ReactPixel from 'react-facebook-pixel';


const URL_BASE = process.env.NODE_ENV === 'production' ? 'https://caveat-website.herokuapp.com' : "http://localhost:5002";
const REACT_URL_BASE = process.env.NODE_ENV === 'production' ? 'https://caveat-website.herokuapp.com' : "http://localhost:3002";

const styles = {
    card: {
      borderRadius: '0',
      boxShadow: 'none',
      position: 'relative',
      aspectRatio: "1"
    },
    content: {
      position: 'absolute',
      zIndex: 2,
      bottom: 0,
      width: '100%',
      marginLeft: "15px"
    },
  };

function CaveatTeam(){


    const [team, setTeam] = useState(null)

    useEffect(()=>{
        axios.get(`${URL_BASE}/api/team`).then(response => {
          setTeam(response.data.data.records)
        }).catch(error => {
          console.log(error)
        })
    
      }, [])



    if(team){
        const teamMembers = team.map((t) =>
        <Grid item key={t.fields.Name} xs={6} sm={4} md={3}>
            <Card sx={styles.card}>
                <CardMedia
                    component="img"
                    height="100%"
                    sx={{filter: "grayscale(1)"}}
                    image={t.fields.Photo[0].url}
                />
                    <Stack sx={styles.content}>
                <Typography variant="quote">{t.fields.Name.toUpperCase()}</Typography>
                <Typography variant="body" sx={{color: "white"}}>{t.fields.Title}</Typography>
                </Stack>
            </Card>
        </Grid>
  )
    return(
        <Box sx={{maxWidth: "1000px", margin: "auto"}}>
            <Box sx={{margin: "40px auto 20px", textAlign: "center"}}><Typography variant="h2">WHO WE ARE</Typography> </Box>
            <DoubleLine text="OUR PEOPLE" size="h4"/>
        <Grid 
            container 
            spacing={2} 
            justifyContent="space-between"
            sx={{marginTop: "20px"}}
        >
            {teamMembers}
        </Grid>
        </Box>
    )
    } else { return("Loading...") }
}

export default function About(){

    ReactGA.initialize([{trackingId: "G-LN3PNYXF7B"}]) 
 

    const fboptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init('336099940147449', fboptions);

    ReactPixel.pageView();
    
    return(
        <Container disableGutters className="container" sx={{padding: "20px"}}>
            <MenuBar active="AboutUs" />
            <Box sx={{marginTop: "20px"}}>
                <HeroUnit
                    textpurple="ABOUT"
                    textblack="US."
                    textcaption="Caveat is a cabaret comedy theater  located deep under Clinton Street. We champion nerdy, funny art – and sell cheap drinks."
                    nobutton
                    imgurl="cat.png"
                />
            </Box>
        <Box sx={{maxWidth: "1000px", justifyContent:"space-between", margin: "auto"}}>
        <Stack sx={{margin: {xs: "40px auto 0", md: "20px auto 0"}, justifyContent: "center"}}>
            <Box sx={{marginTop: "20px"}}>
        <DoubleLine text="" color="black" size="h3"/>
        </Box>
        <Typography sx={{textAlign: "center", maxWidth: "300px", margin: "20px auto"}} variant="body">We LOVE smart, joyfully-nerdy comedy. No disrespect to a classic pratfall and cream pie to the face, but that’s not what does it for us. We want our comedy to say something, mean something, punch up. We celebrate quick wits, sharp observations, and fast-thinking with a side-order of silly. </Typography>
        <Typography variant="h4" sx={{textAlign: "center", textTransform: "uppercase", color: "#662573", margin: "auto", width: "240px", whiteSpace: "normal"}}>Because Life’s about learning, <br/>and learning’s better with jokes.</Typography>
        </Stack>
        </Box>
        <Box sx={{margin: "20px auto", maxWidth: "960px", padding: "20px", backgroundColor: "#282828"}}>
            <DoubleLine text="OUR LOCATION" color="white" size="h3" />
            <Grid container spacing={2} sx={{marginTop: "10px"}}>
                <Grid item xs={12} md={6}>
                    <a href="https://www.google.com/maps/place/Caveat/@40.7207454,-73.986628,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25981f54d9667:0x39729f335791cb9c!8m2!3d40.7206995!4d-73.9843693" target="blank_">
                    <img src="map.png" /> 
                    </a>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Box>
                    <Typography variant="body" sx={{color: "white"}}>
                            Caveat<br/>
                            21A Clinton Street, Manhattan, 10002<br/>
                            (212)-228-2100
                    </Typography>
                    </Box>
                    <Box sx={{margin: "20px 0"}}>
                    <Typography variant="body" sx={{color: "white"}}>
                    Caveat is fully accessible. Access our elevator by calling (212)-228-2100 (also posted on the door). <br/>
                    <br/>
                    Please note, tickets for in-person shows at Caveat do not guarantee a seat unless the show has assigned seating. We’re a cabaret theater, with a standing-room bar. If you or someone in your party needs a seat let us know, and we can reserve a table for you.
                    If you have any questions or concerns, email us at contact@caveat.nyc or call us at 212-228-2100.
                    </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>

        <CaveatTeam />
        <CaveatFooter />
        </Container>    
    )
} 

