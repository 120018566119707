import './index.css';
import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios'
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import ShowList from "./showlist"
import MenuBar from './MenuBar'
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';
import HeroUnit from "./HeroUnit"
import ShowCard from "./ShowCard";
import DoubleLine from "./DoubleLine";
import CaveatFooter from "./CaveatFooter"
import Gallery from "./Gallery"
import ReactMarkdown from 'react-markdown'
import ReactGA from "react-ga"
import ReactPixel from 'react-facebook-pixel';


export default function Menu(){

    return(
        <Container disableGutters className="container">
            <Box sx={{padding: "0 20px"}}>
            <MenuBar active="Pitch" />
            <HeroUnit 
                textpurple="DRINKS"
                textblack="AND FOOD"
                textcaption="Two of the most essential parts of enjoying a show! Preview the menu here. You can order at the bar, or from your table by scanning the QR code."
                imgurl="Octopus_beer.png"
                nobutton
            />
        </Box>

        <object width="100%" height="1600" data="./menu.pdf" type="application/pdf">   </object>
        <CaveatFooter />
        </Container>    
    )

}