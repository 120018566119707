import './index.css';
import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import MenuBar from './MenuBar'
import Container from '@mui/material/Container';
import HeroUnit from "./HeroUnit"
import CaveatFooter from "./CaveatFooter"
import ReactGA from "react-ga"
import ReactPixel from 'react-facebook-pixel';


export default function Shop(){

    ReactGA.initialize([{trackingId: "G-LN3PNYXF7B", gaOptions: {debug_mode: true}}])  
    ReactGA.send({ hitType: "pageview" });


    const fboptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init('336099940147449', fboptions);

    ReactPixel.pageView();
    
    return(
        <Container disableGutters className="container" sx={{padding: "20px"}}>
            <MenuBar active="Shop" />
            <Box sx={{marginTop: "20px"}}>
                <HeroUnit
                    textpurple="COMING"
                    textblack="SOON"
                    textcaption="Check back shortly!"
                    nobutton
                    imgurl="shop.png"
                />
            </Box>
        <Box sx={{marginTop: "40px"}}></Box>
        <CaveatFooter />
        </Container>    
    )
} 

