import './index.css';
import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';



export default function HeroUnit(props){

    const theme = useTheme();

    let button=""

    if(!props.nobutton) {
        button=<Box><Button href={props.buttonlink} variant="contained" sx={{marginTop: "15px", marginBottom: "10px"}}><Typography variant="h5">{props.buttontext.toUpperCase()}</Typography></Button></Box>
    }

    const small = useMediaQuery(theme.breakpoints.down('sm'))

    var length = props.textpurple.length + props.textblack.length
    const maxLength = 100
    const minLength = 35
    var fontScaled = 110
    if(useMediaQuery(theme.breakpoints.down('lg'))) { fontScaled = 110 }
    if(useMediaQuery(theme.breakpoints.down('md'))) { fontScaled = 60 }
    if(useMediaQuery(theme.breakpoints.down('sm'))) { fontScaled = 60 }
    var lineScaled = 0.9
    if(length > minLength){
        fontScaled =  Math.floor(fontScaled*minLength/length)
    }

    const contact =
    <Box sx={{marginTop: "40px"}}>                         
    <Grid container spacing={0}>
    <Grid item xs={1}>
    <PhoneRoundedIcon sx={{fontSize: "24px"}} color="primary" />
    </Grid>
    <Grid item xs={11}>
    <Box sx={{marginLeft: "10px"}}>
        <Typography variant="body"> Call us at (212)-228-2100</Typography>
    </Box>
    </Grid>
    <Grid item xs={1}>
    <EmailIcon sx={{fontSize: "24px"}} color="primary" />
    </Grid>
    <Grid item xs={11}>
        <Box sx={{marginLeft: "10px"}}>
        <Typography variant="body" sx={{fontSize: "12pt"}}> email us at contact@caveat.nyc</Typography>
    </Box>
    </Grid>
    </Grid>
    </Box>
    if(small){
        return(
            <Card sx={{position: "relative"}}>
            <CardMedia
                component="img"
                image={props.imgurl}
                style={{ width: "100%", objectFit: "contain",filter: "sepia(1) hue-rotate(230deg) saturate(68%) brightness(95%)"}}
            />
            <Box sx={{position: "absolute", bottom: "0px", paddingLeft: "10px", width: "100%", backgroundColor: "rgba(256, 256, 256, 0.7)"}}>
                    <Typography variant="hero" sx={{color: "#662573", fontSize: fontScaled, lineHeight: lineScaled}}>{props.textpurple.toUpperCase()} </Typography><Typography variant="hero" sx={{fontSize: fontScaled, lineHeight: lineScaled}}>{props.textblack.toUpperCase()}</Typography>
                    <br/><Typography variant="body" dangerouslySetInnerHTML={{ __html: props.textcaption}}></Typography>
                    {button}
            </Box>
            </Card>
        )
    }
    return(
        <Box sx={{minHeight: "450px", width: {xs: "100%", sm: "100%"}, maxWidth: "1000px", margin: "auto", marginBottom: {xs: "40px auto 0", md: "20px auto 0"}, boxShadow: "0px 4px 1px black"}}>
            
        <Grid direction={{xs: "column-reverse", sm: "row"}} container justifyContent="space-between" spacing={0}>
            <Grid item xs={12} sm={6}>
            <Box sx={{marginLeft: "15px", marginRight: {xs: "15px", sm: "10px"}}}>
            <Box sx={{zIndex: "1", textAlign: "left"}}>
            <Box sx={{marginTop: {xs: "5px", sm: "0px"}}}>
                <Typography variant="hero" sx={{color: "#662573", fontSize: fontScaled, lineHeight: lineScaled}}>{props.textpurple.toUpperCase()}<br/></Typography><Typography variant="hero" sx={{fontSize: fontScaled, lineHeight: lineScaled}}>{props.textblack.toUpperCase()}</Typography>
            </Box>
            <Box display={{ xs: 'none', sm: 'block'}} sx={{marginTop: {xs: "10px", sm: "10px"} }}>
                <Typography variant="body" dangerouslySetInnerHTML={{ __html: props.textcaption}}></Typography>
            </Box>
            {button}
            {props.contact ? contact: ""}
            </Box>
            </Box> 
            </Grid>
            <Grid item xs={12} sm={6}>
            <Box sx={{borderRadius: "0px", margin: "auto", marginTop: {xs: "0px", sm: "0px"}}}>
                <img style={{ width: "100%", maxWidth: "450px", maxHeight: "450px", objectFit: "contain",borderRadius: "0px", marginLeft: "auto", marginRight: small ? "auto" : "0px", filter: "sepia(1) hue-rotate(230deg) saturate(68%) brightness(95%)"}} src={props.imgurl} />
            </Box>
            </Grid>            
        </Grid>
        </Box>
        
    )
}