import './index.css';
import './App.css';
import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DoubleLine from "./DoubleLine"
import Link from '@mui/material/Link';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import { ReactComponent as TikTokIcon } from './tiktok.svg'
import SvgIcon from '@mui/material/SvgIcon';

const URL_BASE = process.env.NODE_ENV === 'production' ? 'https://caveat-website.herokuapp.com' : "http://localhost:5002";
const REACT_URL_BASE = process.env.NODE_ENV === 'production' ? 'https://caveat-website.herokuapp.com' : "http://localhost:3002";


export default function CaveatFooter(){

    const [insta, setInsta] = useState(null)

    useEffect(()=>{
        axios.get(`${URL_BASE}/api/insta`).then(response => {
          setInsta(response.data.data.slice(0,9))
        }).catch(error => {
          console.log(error)
        })
    
      }, [])

    function TextFlairBox(){

        return(
            <Box border="4px double black" spacing={4} sx={{padding: "20px", margin: "16px 0px 0px 0px"}}>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Typography align="center" variant="h3" sx={{color: "#662573"}}>THINGS WE LOVE</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography align="center" variant="h3" sx={{fontSize: "40pt"}}>(COMING SOON...)</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box sx={{margin: "auto"}}>
                         <img width="40%" style={{opacity: "80%", margin: "auto"}} src="/moth.png"/>
                        </Box>
                    </Grid>
                </Grid>
            </Box> 
        )
    } 

    function InstagramBox(){

        if(insta === null){ return("Loading...")}
        else {
            const instaposts = insta.map((post) =>
                <Grid key={post.id} item xs={4}>
                    <Link href={post['permalink']} ><img src={post['media_type'] === "VIDEO" ? post['thumbail_url'] : post[ 'media_url' ]} /></Link>
                </Grid>
            )
            return(
                <Grid container spacing={3} sx={{marginBottom: "20px"}} >
                {instaposts}
                </Grid>
                )
        }
    } 

    function ContactBox(){

        return( 
            <Grid container sx={{margin: "auto"}}>
                <Grid item xs={12} sm={6} sx={{marginBottom: "20px"}}>
                    <Stack direction="column" alignItems="center" sx={{marginLeft: "20px"}}>
                    <Box sx={{marginBottom: "30px", marginTop: "80px"}}>
                        <img width="40%" style={{opacity: "30%", margin: "auto"}} src="/contact-hand.png"/>
                    </Box>
                    <Box sx={{width: "70%", marginLeft: "20px"}}>
                            <Typography variant="h3" sx={{fontSize: {xs: "18pt", lg: "22pt"}}}>KEEP IN TOUCH WITH ALL THINGS CAVEAT!</Typography>
                        </Box>
                        <Stack direction="row" sx={{marginTop: "20px"}}>
                            <Link href="https://twitter.com/caveatnyc" ><TwitterIcon sx={{fontSize: "45px"}}/></Link>
                            <Link href="https://www.instagram.com/caveatnyc/" ><InstagramIcon sx={{fontSize: "45px"}}/></Link>
                            <Link href="https://www.facebook.com/caveatnyc" ><FacebookIcon sx={{fontSize: "45px"}}/></Link>
                            <Link href="https://www.tiktok.com/@caveatnyc" ><SvgIcon sx={{fontSize: "40px"}}><TikTokIcon /></SvgIcon></Link>
                            <Link href="http://eepurl.com/c2qZTz" ><EmailIcon sx={{fontSize: "45px"}}/></Link>
                            <Box>

                            </Box>
                        </Stack>

                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} sx={{paddingTop: "20px"}}>
                    <InstagramBox />
                </Grid>
            </Grid>
        )
    }

    return(
        <Box sx={{maxWidth: "1000px", margin: "auto", height: "685px"}}>
        <ContactBox />
         <Box><DoubleLine />
        <Box><Typography variant="body">© 2022 Caveat</Typography></Box>
        </Box></Box>
    )

}