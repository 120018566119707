import "./index.css"
import { createTheme } from '@mui/material/styles';



let theme = createTheme()

theme = createTheme({
    palette: {
        type: 'light',
        primary: {
          main: '#662573',
        },
        secondary: {
          main: '#cc31ca',
        },
        action: {
          active: "#662573"
        }
    }, 
    typography: {
        h1: {
            fontFamily: '"FK Screamer", "Helvetica"',
            fontSize: '110px',
            fontWeight: "400",
            [theme.breakpoints.down('xs')]: {
              fontSize: '80px'
            },
            [theme.breakpoints.only('sm')]:{
              fontSize: '110px'
            }
        },
        hero: {
          fontFamily: '"FK Screamer", "Helvetica"',
          fontSize: '110px',
          fontWeight: "400",
      },
        h2: {
          fontFamily: '"FK Screamer", "Helvetica"',
          fontSize: '60px',
          fontWeight: "400",
          [theme.breakpoints.down('xs')]: {
            fontSize: '80px'
          }
      },
        showtitle: {
          fontFamily: '"FK Screamer", "Helvetica"',
          fontSize: '60px',
          fontWeight: "400",
          [theme.breakpoints.down('xs')]: {
            fontSize: '30px'
          }
      },
      showdate: {
        fontFamily: '"FK Screamer", "Helvetica"',
        fontSize: '30px',
        fontWeight: "400",
        [theme.breakpoints.down('xs')]: {
          fontSize: '20px'
        }
    },
        h3: {
            fontFamily: '"FK Screamer", "Helvetica"',
            fontSize: '32px',
            fontWeight: "400",
            [theme.breakpoints.down('sm')]: {
              fontSize: '24px'
            }
        },
        h4: {
          fontFamily: '"FK Screamer", "Helvetica"',
          fontSize: '1.5rem',
          fontWeight: "400",
          whiteSpace: "nowrap",
          [theme.breakpoints.down('lg')]: {
            fontSize: "1rem"
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: "2rem"
          }
        },
        h4Title : {
          fontFamily: '"FK Screamer", "Helvetica"',
          fontSize: '1.5rem',
          fontWeight: "400",
        },
        h5: {
          fontFamily: '"FK Screamer", "Helvetica"',
          fontSize: '1.2rem',
          fontWeight: "400",
          [theme.breakpoints.down('xs')]: {
            fontSize: "1.2rem"
          }
        },
        body: {
            fontFamily: '"ProximaNova", "Sans Serif"',
            fontSize: "12pt",
            lineHeight: "120%",
            marginBottom: "1em",
        },
        smallbody: {
          fontFamily: '"ProximaNova", "Sans Serif"',
          fontSize: "10pt",
          lineHeight: "120%",
          marginBottom: "1em",
      },
        label: {
          fontFamily: '"ProximaNova", "Sans Serif"',
          fontSize: "10pt",
      },
        subtitle1: {
            fontFamily: '"FK Screamer", "Helvetica"',
            fontSize: '1rem',
            fontWeight: "400",
            color: '#5bb5bf'
         },
        quote: {
          fontFamily: '"FK Screamer", "Helvetica"',
          fontSize: '1.6rem',
          color: '#ffffff'
        },
        source: {
          fontFamily: '"ProximaNova", "Sans Serif"',
          fontSize: "1rem",
          color: '#ffffff'
        },
        calendarTitle: {
          fontFamily: '"FK Screamer", "Helvetica"',
          fontSize: "18px",
          underline: "hover",
          color: "black",
          lineHeight: "0.5"
        },
        calendarTime: {
          fontFamily: '"ProximaNova", "Sans Serif"',
          fontSize: "14px",
          color: '#AEAEAE'          
        }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            padding: '2px 20px',
            whiteSpace: "nowrap"

          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            borderRight: "2px solid",
            borderColor: "#cdcdcd",
            borderWidth: "0",
            background: 'linear-gradient(0deg, #ffffff 0%, #ededed 90%)',
            [theme.breakpoints.down('sm')]: {
              background: 'linear-gradient(270deg, #ffffff 0%, #ededed 90%)',
              borderRight: "none",
              borderBottom: "2px solid",
              borderColor: '#cdcdcd'
            }
          }
        }
      },
      Box: {
        variants: [
          {
            props: { variant: 'main'},
            style: {
              border: "10px",
              borderColor: "red"
            }
          }
        ]
      }
    }
});

export default theme