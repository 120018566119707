import './index.css';  
import './App.css';
import './Calendar.css'; 
import './CalendarView.css'
import React from 'react';
import Box from '@mui/material/Box';
import MenuBar from './MenuBar'
import Container from '@mui/material/Container';
import HeroUnit from "./HeroUnit"
import DoubleLine from "./DoubleLine"
import CaveatFooter from "./CaveatFooter"
import ShowUnit from "./ShowUnit"
import ReactGA from "react-ga4"
import ReactPixel from 'react-facebook-pixel';
    

export default function WhatsOn () {

    ReactGA.initialize([{trackingId: "G-LN3PNYXF7B"}]) 
 

    const fboptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init('336099940147449', fboptions);

    ReactPixel.pageView();

    return(
        <Container className="container">
            <MenuBar active="WhatsOn" />
            <Box sx={{marginTop: "20px"}}>
                <HeroUnit
                    textpurple="What's"
                    textblack="On."
                    textcaption="Join us for a drink, or relax on your sofa and let our motley band of glorious nerds, pun-masters, West Wing speech writers, award-winning scientists, New Yorker cartoonists, powerpoint professionals and all-round hilarious people entertain you. In-person or live-streaming, it’s going to be a good time.  "
                    nobutton
                    imgurl="heart.png"
                />
            </Box>

            <Box sx={{backgroundColor: "white", maxWidth: "1000px", minHeight: {xs: "2320px", sm: "1130px", md: "600px"}, margin: "auto", padding: "20px", marginTop: "20px"}}>
                <Box sx={{marginBottom: "10px", width: "100%"}} >
                <DoubleLine text="SEARCH OUR SHOWS" size="h3"/>
                </Box>
                <ShowUnit />
            </Box>
            <CaveatFooter />
        </Container>    

    )
} 