import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios'
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Carousel from 'react-material-ui-carousel'
import StarBorderPurple500RoundedIcon from '@mui/icons-material/StarBorderPurple500Rounded';

const URL_BASE = process.env.NODE_ENV === 'production' ? 'https://caveat-website.herokuapp.com' : "http://localhost:5002";
const REACT_URL_BASE = process.env.NODE_ENV === 'production' ? 'https://caveat-website.herokuapp.com' : "http://localhost:3002";

export default function QuoteBox(){

    const [quotes, setQuotes] = useState(null)
    const [quoteCards, setQuoteCards] = useState("")

    useEffect(()=>{
        axios.get(`${URL_BASE}/api/quotes/press`).then(response => {
          setQuotes(response.data.data)
        }).catch(error => {
          console.log(error)
        })
    
      }, [])

    useEffect(() => {
        if(quotes === null) {  }
        else {
        const cards = quotes.map((quote) => 
            <Box key={quote.Quote} sx={{margin: "auto"}}>
            <Box sx={{width: {xs: "80%", sm: "40%"}, margin: "auto", textAlign: "center"}}><Typography variant="quote">{quote.Quote.toUpperCase()}</Typography></Box>
            <Box sx={{width: "60%", margin: "auto", textAlign: "center", marginTop: "5px"}}><Typography variant="source">~ {quote.Source}</Typography></Box>
            </Box>
        )
        setQuoteCards(cards)
        }
    }, [quotes])


    return(
        <Box sx={{width: "100%", backgroundColor: "#282828", paddingTop: "100px", paddingBottom: "100px"}}>
            <Carousel
                IndicatorIcon={<StarBorderPurple500RoundedIcon/>}
                interval={8000}
                sx={{minHeight: "10rem"}}
            >
                {quoteCards}
            </Carousel>
            <Box sx={{margin: "auto", textAlign: "center", marginTop: "10px"}}>
            <Button href="/events" variant="contained"><Typography variant="h5">LEARN MORE ABOUT OUR SHOWS</Typography></Button> 
            </Box>
        </Box>
    )
 
}