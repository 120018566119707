import './index.css';
import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuBar from './MenuBar'
import Container from '@mui/material/Container';
import HeroUnit from "./HeroUnit"
import ShowCard from "./ShowCard";
import DoubleLine from "./DoubleLine";
import CaveatFooter from "./CaveatFooter"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from './caveattheme';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


const styles = {
    card: {
      borderRadius: '0',
      boxShadow: 'none',
      position: 'relative',
      aspectRatio: "1"
    },
    content: {
      position: 'absolute',
      zIndex: 2,
      bottom: 0,
      width: '100%',
      marginLeft: "15px"
    }, 
  };

  function SampleCard(props){

    var show = props.show
    return(
        <Card key={show.id}
        sx={{
            borderRadius: "0",
            transition: "0",
            width: "100%"
            }} 
    >
            <CardActionArea href={"/events/"+show.fields.slug}>
            <CardMedia
                sx={{aspectRatio: "2"}}
                image={show.fields.s3image}
            />
            </CardActionArea>
    </Card>
    )
  }

  function SampleShows(props,select){

    const [filter, setFilter] = useState("Upcoming")

    var defaultShows = 4


        if(!props.shows){ return("Loading...")}

        var filteredShows = props.shows.slice(0,6)
        if(filter != "Upcoming"){
            filteredShows = props.shows.filter(res=>res.fields['Tags'].includes(filter)).slice(0,6)
        }
        var showList = filteredShows.map((show) =>
            <Grid key={show.id} justifyContent="center" item container xs={12} sm={6} md={4} lg={6}><SampleCard show={show}/></Grid>
        )

        function handleChangeDisplay(event){
            console.log(event)
            setFilter(event.nativeEvent.target.value)
        }

        return(

            <Box sx={{margin: "20px auto", maxWidth: "680px", padding: "10px 20px", backgroundColor: "#282828"}}>

                <Box sx={{backgroundColor: "white", marginTop: "10px"}}>
                    <ToggleButtonGroup
                            value={filter}
                            exclusive
                            onChange={handleChangeDisplay}
                            color="secondary"
                            size="small"
                            fullWidth
                            sx={{height: "55px"}}
                        >
                            <ToggleButton value="Upcoming" sx={{border: "none", borderRadius: "0"}}>UPCOMING</ToggleButton>
                            <ToggleButton value="Standup" sx={{border: "none", borderRadius: "0"}}>STANDUP</ToggleButton>
                            <ToggleButton value="Science" sx={{border: "none", borderRadius: "0"}}>SCIENCE</ToggleButton>
                            <ToggleButton value="Culture" sx={{border: "none", borderRadius: "0"}}>CULTURE</ToggleButton>
                            <ToggleButton value="Date Night" sx={{border: "none", borderRadius: "0"}}>DATE NIGHT</ToggleButton>
                        </ToggleButtonGroup>
                </Box>

                <Box sx={{marginTop: "20px"}}>
                <Grid container justifyContent="center" spacing={2}>
                    {showList}
                <Grid item xs={12}>
                    <Box sx={{width: "100%", margin: "auto"}}>
                    </Box>
                </Grid>
                </Grid>
                </Box>
                <Box sx={{margin: "auto", textAlign: "center"}}><Button variant="contained" href="/events">EXPLORE ALL OUR SHOWS</Button></Box>
            </Box>
            )
}

export default function Subscribe(){

    const [shows, setShows] = useState(null)

    useEffect(()=>{
      axios.get(`/api/events/upcoming`).then(response => {
        console.log(response.data.records)
        setShows(response.data.records)
      }).catch(error => {
        console.log(error)
      })
  
    }, [])
    
    return(
        <Container disableGutters sx={{ background: "black", color: "white", width: "100%"}}>

            <Box sx={{}}>
                <Box sx={{height: "200px", backgroundImage: "linear-gradient(-5deg, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 100%), url('../subscribebg.jpg')", padding: "200px"}}>
                    <Box sx={{margin: "auto", width: "fit-content"}} >
                        <Box sx={{margin: "auto", textAlign: "center"}}>
                            <Typography variant="h3" sx={{fontSize: "120px", color: theme.palette.primary.main}}>COMEDY @ HOME</Typography>
                        </Box>
                        <Box sx={{textAlign: "right"}}>
                            <Typography variant="h3">Presented by Caveat</Typography>
                        </Box>
                    </Box>
                    <Box sx={{textAlign: "center", marginTop: "60px"}}><Button variant="contained" href="https://caveat.stellartickets.com/subscriptions/78a7a945-4be3-4342-b092-c834db57da39"><strong>WATCH NOW</strong></Button></Box>
                </Box>
                <Box sx={{width: "500px", textAlign: "left", margin: "50px auto"}}>
                    <Typography sx={{marginBottom: "20px"}} variant="h3">{"Love comedy? Hate wearing pants?".toUpperCase()} </Typography>
                    <Typography>Welcome to Comedy at Home, our streaming service from our LES cellar straight to your living room, bathroom, wild cat sanctuary, or wherever else you hang out. </Typography>
                    <Typography sx={{margin: "50px auto 20px"}}>How does it work?</Typography>
                
                <Box sx={{marginLeft: "20px"}}>
                    <Typography><Box component="span" sx={{color: theme.palette.secondary.main, fontWeight: "700"}}>1.</Box> Subscribe to Comedy at Home for $69.00 per year. </Typography>
                    <Typography><Box component="span" sx={{color: theme.palette.secondary.main, fontWeight: "700"}}>2.</Box> Get your Comedy at Home password. </Typography>
                    <Typography><Box component="span" sx={{color: theme.palette.secondary.main, fontWeight: "700"}}>3.</Box> Watch shows live from Caveat, or catch up on over 100 shows on demand. </Typography>
                </Box>
                </Box>

                <Box sx={{textAlign: "center"}}><Button variant="contained" href="https://caveat.stellartickets.com/subscriptions/78a7a945-4be3-4342-b092-c834db57da39"><strong>SUBSCRIBE NOW</strong></Button></Box>

                <SampleShows shows={shows} />
                
                </Box>

                <Box sx={{textAlign: "left", margin: "20px auto 50px auto", width: "60%", background: "white"}}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Does the monthly subscription really cover ALL your shows?
                        </AccordionSummary>
                        <AccordionDetails>
                            Yes! Any show with a livestream option is included in the monthly subscription.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Including RAAAATSCRAPS?
                        </AccordionSummary>
                        <AccordionDetails>
                        Yes. Including RAAATSCRAPS
                        </AccordionDetails>
                    </Accordion>
                    
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        So the subscription works out at way less than a dollar a show?!
                        </AccordionSummary>
                        <AccordionDetails>
                        Yes.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        But I only want to watch ONE SHOW. Can I?
                        </AccordionSummary>
                        <AccordionDetails>
                        Absolutely. You can still buy a single ticket to a show.
                    Watch live or on-demand. Easy. Simple. Done.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Who’s Comedy at Home for?
                        </AccordionSummary>
                        <AccordionDetails>
                        For everyone who loves smart, nerdy comedy but can’t get to Caveat tonight - shoutout to Kate in LA, my Mom in Chicago, and my roommate Johanas who lives down the block but is basically hibernating at this point.
                    
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Does this mean live, in-person comedy is dead?
                        </AccordionSummary>
                        <AccordionDetails>
                        We frickin’ hope not.
                    We love watching a great show from home, but nothing beats being in the room, laughing our asses off, drinking some beers, eating popcorn, meeting some fellow comedy nerds, and seeing where the night takes us.
                    None of us moved to NYC to sit on the sofa all night. So join us! Come out and play! But if you can’t… get Comedy at Home.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Do Comedy at Home subscribers get extra perks if we come to Caveat in person?
                        </AccordionSummary>
                        <AccordionDetails>
                        You sure do. Comedy at Home Subscribers always get a free popcorn.
                        </AccordionDetails>
                    </Accordion>
                </Box>                                      
                
                 
            

        </Container>    
    )
} 

