import './index.css';
import './App.css';
import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import MuiMarkdown from 'mui-markdown';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import MenuBar from './MenuBar'
import Container from '@mui/material/Container';
import DoubleLine from "./DoubleLine"
import CaveatFooter from "./CaveatFooter"
import { useTheme } from '@mui/material/styles';
import { CardActionArea, DialogContentText } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WebIcon from '@mui/icons-material/Web';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ReactGA from "react-ga4"
import ReactPixel from 'react-facebook-pixel';
import { ReactComponent as TikTokIcon } from './tiktok.svg'
import SvgIcon from '@mui/material/SvgIcon';
import ShowCard from './ShowCard'
import useMediaQuery from '@mui/material/useMediaQuery';


export default function Performers(){

    const [performers, setPerformers] = useState(null)

    const fboptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init('336099940147449', fboptions);
    ReactPixel.pageView();
    ReactGA.initialize([{trackingId: "G-LN3PNYXF7B"}]) 

    useEffect(()=>{
        axios.get(`/api/performers`).then(response => {
          setPerformers(response.data.records)
          console.log(response.data)
        }).catch(error => {
          console.log(error)
        })
    
      }, [])

      useEffect(() => {
          document.title = "Caveat | Performers"
          ReactGA.send({ hitType: "pageview" });
          ReactPixel.pageView();
      }, []);

    function PerformerGrid(){
        if(! performers) { return("") }

        const list = performers.map((p) => 
            <Grid key={p.id} item xs={6} sm={4} md={2}>
                <Card>
                <CardActionArea href={"/performer/"+p.id}>
                <CardMedia 
                    component="img"
                    height="100%"
                    sx = {{aspectRatio: "1", objectPosition: "50% 0"}}
                    image={p.fields.s3image}
                />
                <Box sx={{margin: "10px 10px"}} spacing={0}>
                <Typography variant="body" sx={{fontWeight: "700"}}>
                {p.fields.Name}
                </Typography>
                <Box>
                <Typography variant="body" sx={{fontWeight: "400"}}>
                {p.fields.Title}
                </Typography>
                </Box>
                </Box>
                </CardActionArea>
                </Card>
            </Grid>
      )
      return(
        <Grid container spacing={2}>
            {list}
        </Grid>
      )

    }

    if(! performers){
        return("Loading...")
    }
    return(

        <Container className="container">
        <MenuBar active="Performers" />
<Box className='main'>
    <Box sx={{marginTop: "30px"}}>
        <DoubleLine
            size="showtitle"
            color="#662573"
            text="PEOPLE OF THE CAVEAT STAGE"
        />
    </Box>
    <Box className="main">
        <Grid container sx={{marginTop: "40px"}} spacing={2}>
            <PerformerGrid />
       </Grid>
    </Box>
</Box>

        <CaveatFooter />
        </Container>
    )
}