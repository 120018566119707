import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios'
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Carousel from 'react-material-ui-carousel'
import { useParams, useSearchParams } from "react-router-dom";

const REACT_URL_BASE = process.env.NODE_ENV === 'production' ? 'https://caveat-website.herokuapp.com' : "http://localhost:3002";


export default function ShowCard(props){

    const show = props.show 

    function TagsList(props){
        if(props != null){
        let tags = props.props.split(",")
        const taglist = tags.map((tag) => 
            <Box key={tag}>
            <Link variant="subtitle1" color="#5bb5bf" underline="hover" href={"/events/?tag="+tag}>{tag.toUpperCase()}</Link>
            </Box>
        )
        return(
            <Box>
                {taglist}
            </Box>
        )
        }else { return("")}
    }

    if(props.wide){
        var cardLeft =
        <Stack 
        spacing={2}
        className="show-info-left"
        divider={<Divider color="black" orientation="horizontal" flexItem />}
        >
        <Typography variant="h4" sx={{whiteSpace: "nowrap"}}>{show.fields.dateFormatted.toUpperCase()}</Typography>
        <TagsList props={show.fields[ 'Tags' ]}/>
    </Stack>    

    }
    var width = "200px"
    if(props.wide){
        width = "350px"
    }

    function adjustedDescription(show){
        var maxlen = 100

        var desc = show.fields[ 'Short description' ].slice(0,maxlen)
        if(desc.length === maxlen){ desc = desc+"..." }
        return(desc)
    }
    
    function headlineHeight(show){
        var headlen = show.fields.Event.length
        var size = "1.5rem"

        if(headlen > 30){ size = "1.2rem"}
        if(headlen > 51){ size = "1rem"}

        return(size)
    }
    return(
                <Card key={show.id}
                sx={{
                    borderRadius: "0",
                    transition: "0",
                    width: "100%"
                    }} 
            >
                    <CardActionArea href={"/events/"+show.fields.slug}>
                    <CardMedia
                        sx={{aspectRatio: "2"}}
                        image={show.fields.s3image}
                    />
                    </CardActionArea>
                    <Box sx={{marginLeft: "10px", marginBottom: "10px", marginRight: "10px", marginTop: "10px"}}>      
                        <Stack direction="column" justifyContent="space-between" sx={{height: props.short ? "100px" : "290px"}}>
                        <Stack>
                            <Stack direction="row" justifyContent="space-between">
                                <Box>
                                    <Typography variant="h5" component="span">{show.fields.dateFormatted.toUpperCase()} &nbsp;&nbsp;</Typography><Typography variant="h5" color="#AEAEAE" component="span">{show.fields.StartTimeFormatted}</Typography>
                                </Box>
                            </Stack>
                            <Typography variant="h4Title" className="show-title" sx={{fontSize: headlineHeight(show), lineHeight: "1"}}>{show.fields[ 'Event' ].toUpperCase()}</Typography>
                            <Typography variant="smallbody" sx={{fontWeight:600, lineHeight: ".5", color: "#662573"}}>{show.fields.hasOwnProperty('Livestream') ? "In-person & Livestream" : "In-person"}</Typography>
                            {props.short ? "" : <Typography variant="body" >{adjustedDescription(show)}</Typography>}
                            {props.short ? "" : <Link variant="body" href={"/events/"+show.fields.slug}>See more</Link>}
                        </Stack>
                        <Stack direction="row" spacing={2}>
                        {props.short ? "" : <Button variant="contained" href={show.fields['UseFeverURLFormula'] ? "https://"+show.fields[ 'Fever URL' ] : show.fields[ 'Stellar URL' ]} sx={{width: "100%", backgroundColor: show.fields.hasOwnProperty('Sold out') ? "#cc31ca" : "" }} >
                            <Typography variant="h5" >      
                            { show.fields.hasOwnProperty('Sold out') ?
                                    "SOLD OUT"
                                    :
                                    "TICKETS"
                                }
                            </Typography>
                            </Button>}
                            {props.short ? "" : 
                            show.fields.hasOwnProperty('Livestream') ? 
                            <Button variant="contained" href={show.fields[ 'Stellar URL' ]} sx={{width: "100%"}} >
                            <Typography variant="h5" >      
                            { show.fields.hasOwnProperty('Sold out') ?
                                show.fields.hasOwnProperty('Livestream')?
                                    "LIVESTREAM"
                                    :
                                    "SOLD OUT"
                                    :
                                    "LIVESTREAM"
                                }
                            </Typography>
                            </Button> : ""}
                            </Stack>
                        </Stack>
                    </Box>
            </Card>
    )
}