import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';

const URL_BASE = process.env.NODE_ENV === 'production' ? 'https://caveat-website.herokuapp.com' : "http://localhost:5002";
const REACT_URL_BASE = process.env.NODE_ENV === 'production' ? 'https://caveat-website.herokuapp.com' : "http://localhost:3002";


export default function Gallery(props){

    const [photos, setPhotos] = useState(null)
    const [open, setOpen] = useState(false)
    const [current, setCurrent] = useState(null)

    const styles = {
        card: {
          boxShadow: 'none',
          position: 'relative',
          aspectRatio: "1"
        },
        content: {
          position: 'absolute',
          zIndex: 2,
          bottom: 0,
          width: '100%',
          marginLeft: "15px"
        },
      };

    useEffect(()=>{
        axios.get(`${URL_BASE}/api/photos/${props.location}`).then(response => {
            console.log(response)
          setPhotos(response.data.records)
        }).catch(error => {
          console.log(error)
        })
    
    }, [])

    function handleClickOpen(p){
        setCurrent(p)
        setOpen(true)
        console.log(p)
    }

    function handleClose(){
        setOpen(false)
    }

    function PhotoDialog(){
        if(current){
          return(
            <Dialog
              open={open}
              onClose={handleClose}
            >
            <Box sx={{width: "100%"}}>
              <img width="100%" src={current.fields.s3Full} />
            </Box>
            </Dialog>
          )
        }
      }

    if(photos){
        var photoList = photos.map((p) => 
            <Grid item key={p.id} xs={6} sm={3}>
                <Card sx={styles.card}>
                    <CardActionArea sx={{height: "100%"}} onClick={() => handleClickOpen(p)}>
                    <CardMedia
                        component="img"
                        height="100%"
                        image={p.fields.s3image}
                    />
                    </CardActionArea>
                </Card>
            </Grid>
        )

        return(
            <Box sx={{maxWidth: "1000px", margin: "auto"}}>
            <Grid 
                container 
                spacing={2} 
                justifyContent="space-between"
            >
                {photoList}
            </Grid>
            <PhotoDialog />
            </Box>
        )
    }
    else { return("Loading...")}



}