import './index.css';
import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import MenuBar from './MenuBar'
import Container from '@mui/material/Container';
import HeroUnit from "./HeroUnit"
import CaveatFooter from "./CaveatFooter"
import ReactGA from "react-ga4"
import ReactPixel from 'react-facebook-pixel';

export default function NotFound(){

    ReactGA.initialize([{trackingId: "G-LN3PNYXF7B"}]) 
 

    const fboptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init('336099940147449', fboptions);

    ReactPixel.pageView();

    return(
        <Container disableGutters className="container" sx={{padding: "20px"}}>
            <MenuBar />
            <HeroUnit 
                textpurple="404"
                textblack="NOT FOUND"
                nobutton
                imgurl="shop.png"
            />
        <Box sx={{marginTop: "40px"}}></Box>
        <CaveatFooter />
        </Container>    
    )

}