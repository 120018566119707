import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from "react-ga4"
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import theme from './caveattheme'
import App from './App';
import WhatsOn from './WhatsOn'
import ShowPage from './ShowPage'
import About from './About'
import Pitch from './Pitch'
import Rental from './Rental'
import Contact from './Contact'
import Shop from './Shop'
import Menu from './Menu'
import Performer from './Performer'
import Performers from './Performers'
import Subscribe from './Subscribe'
import Test from './Test'
import NotFound from './NotFound'

ReactGA.initialize([{trackingId: "G-LN3PNYXF7B"}]) 

  
const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <ThemeProvider theme={theme}>
    
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<App />} />
    <Route path="/events" element={<WhatsOn />} />
      <Route path="/events/:event" element={<ShowPage />} />
    <Route path="/series/:event" element={<ShowPage />} />
    <Route path="/event/:event" element={<ShowPage />} /> 
    <Route path="/performer/" element={<Performers />} />
    <Route path="/performers/" element={<Performers />} />
    <Route path="/performer/:atrec" element={<Performer />} />
    <Route path="/about" element={<About />} />
    <Route path="/pitch" element={<Pitch />}/>
    <Route path="/rentals" element={<Rental />}/>
    <Route path="/contact" element={<Contact />}/>
    <Route path="/shop" element={<Shop />}/>
    <Route path="/menu" element={<Menu />}/>
    <Route path="/raaaatscraps" element={<Navigate to="/events/raaaatscraps"/>}/>
    <Route path="/test/subscribe" element={<Subscribe />}/>
    <Route path="/test" element={<Test />}/>
    <Route path="*" element={<NotFound />}/>
    
  </Routes>

  </BrowserRouter>


  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
