import './index.css';
import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios'
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import ShowList from "./showlist"
import MenuBar from './MenuBar'
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';
import HeroUnit from "./HeroUnit"
import ShowCard from "./ShowCard";
import DoubleLine from "./DoubleLine";
import CaveatFooter from "./CaveatFooter"
import Gallery from "./Gallery"
import ReactMarkdown from 'react-markdown'
import ReactGA from "react-ga4"
import ReactPixel from 'react-facebook-pixel';

export default function Rental(){

    ReactGA.initialize([{trackingId: "G-LN3PNYXF7B"}]) 
 

    const fboptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init('336099940147449', fboptions);

    ReactPixel.pageView();

    const styles = {
        card: {
          boxShadow: 'none',
          position: 'relative',
          backgroundColor: 'transparent'
        }
      };

    const entries = [
        "Seating for 120 at cabaret tables",
        "Bar serving a large selection of craft beer, wine and bar snacks",
        "Private greenroom",
        "Kitchen space for simple prep",
        "Full audio setup & tech",
        "Full spectrum LED stage lighting",
        "3 in-house cameras with ability to livestream",
        "Multitrack audio recording",
        "Projector (12' wide screen)",
        "No sound or light leak from street"
    ]

    const entryList = entries.map((e) =>
        <Grid item xs={12} sm={4} md={3}>
            <Card sx={styles.card}>
            <Typography variant="h4Title">{e.toUpperCase()}</Typography>
            </Card>
        </Grid>
    )
    
    return(
        <Container disableGutters className="container">
            <Box sx={{padding: "0 20px"}}>
            <MenuBar active="Rentals" />
            <Box sx={{marginTop: "20px"}}>
                <HeroUnit
                    textpurple="RENT"
                    textblack="OUR SPACE"
                    textcaption="Got a book to launch, a party to throw, a film to screen, a photo to shoot or a pod to cast – our stylish LES cabaret speakeasy could be just the place you’re looking for."
                    buttonlink="mailto:rentals@caveat.nyc"
                    buttontext="LET'S TALK"
                    imgurl="Octopus_beer.png"
                />
            </Box>
        </Box>

         <Box sx={{padding: "0 20px"}}>
        <Box sx={{textAlign: "center", marginBottom: "20px"}}>
         <DoubleLine text="GALLERY" size="h3"/>
         <Box sx={{marginTop: "10px"}}>
         <Typography variant="body">Take a look around our 3500 square foot cabaret-style speakeasy theater</Typography>
         </Box>
         </Box>
         <Gallery location="pitch" />
         <Box sx={{marginBottom: "20px", marginTop: "20px"}}>
            <DoubleLine text="THE DETAILS" size="h3"/>
         </Box>
         <Box sx={{maxWidth: "1000px", margin: "auto"}}>
         <Grid 
            container
            spacing={3} 
            justifyContent="space-between"
         >
            {entryList}
         </Grid>
         </Box>
         </Box>
         <Box sx={{width: "100%", backgroundColor: "#282828", paddingTop: "50px", paddingBottom: "75px", textAlign: "center", marginBottom: "75px", marginTop: "40px"}}>
            <Stack sx={{maxWidth: "500px", margin: "auto"}}>
                <Typography variant="quote" sx={{textTransform: "uppercase"}}>“I spent the last couple nights @caveatnyc. I’ve built hundreds of events over the last 10 years and none of them have felt like home in the way Caveat has. Go there. Host an event there. Enjoy that a space like that exists.”
                </Typography>
                <Typography variant="source">
                <br/>- Kishore Hari, 
Founding director Bay Area Science Festival
                </Typography>
            </Stack>
         </Box>
        <CaveatFooter />
        </Container>    
    )
} 

