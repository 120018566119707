import './index.css';
import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios'
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import ShowList from "./showlist"
import MenuBar from './MenuBar'
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';
import HeroUnit from "./HeroUnit"
import ShowCard from "./ShowCard";
import DoubleLine from "./DoubleLine";
import CaveatFooter from "./CaveatFooter"
import ReactMarkdown from 'react-markdown'
import ReactGA from "react-ga4"
import ReactPixel from 'react-facebook-pixel';


const URL_BASE = process.env.NODE_ENV === 'production' ? 'https://caveat-website.herokuapp.com' : "http://localhost:5002";
const REACT_URL_BASE = process.env.NODE_ENV === 'production' ? 'https://caveat-website.herokuapp.com' : "http://localhost:3002";

const styles = {
    card: {
      borderRadius: '0',
      boxShadow: 'none',
      position: 'relative',
      aspectRatio: "1"
    },
    content: {
      position: 'absolute',
      zIndex: 2,
      bottom: 0,
      width: '100%',
      marginLeft: "15px"
    },
  };



export default function Contact(){

    ReactGA.initialize([{trackingId: "G-LN3PNYXF7B"}]) 
 

    const fboptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init('336099940147449', fboptions);

    ReactPixel.pageView();
    
    return(
        <Container disableGutters className="container" sx={{padding: "20px"}}>
            <MenuBar active="Contact" />
            <Box sx={{marginTop: "20px"}}>
                <HeroUnit
                    textpurple=""
                    textblack="CONTACT"
                    textcaption=" "
                    contact
                    nobutton
                    imgurl="contact.png"
                />
            </Box>

        <Box sx={{margin: "40px auto", maxWidth: "960px", padding: "20px", backgroundColor: "#282828"}}>
            <DoubleLine text="OUR LOCATION" color="white" size="h3" />
            <Grid container spacing={2} sx={{marginTop: "10px"}}>
                <Grid item xs={12} md={6}>
                    <a href="https://www.google.com/maps/place/Caveat/@40.7207454,-73.986628,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25981f54d9667:0x39729f335791cb9c!8m2!3d40.7206995!4d-73.9843693" target="blank_">
                    <img src="map.png" /> 
                    </a>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Box>
                    <Typography variant="body" sx={{color: "white"}}>
                            Caveat<br/>
                            21A Clinton Street, Manhattan, 10002<br/>
                            (212)-228-2100
                    </Typography>
                    </Box>
                    <Box sx={{margin: "20px 0"}}>
                    <Typography variant="body" sx={{color: "white"}}>
                    Caveat is fully accessible. Access our elevator by calling (212)-228-2100 (also posted on the door).<br/>
                    </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>

        <CaveatFooter />
        </Container>    
    )
} 

