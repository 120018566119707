import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function DoubleLine(props){

    if(props.text){
    return(

        <Stack direction="row" sx={{maxWidth: "1000px", margin: "auto"}}>
            <Box sx={{color: props.color, height: "3px", width: "100%", margin: "auto 0px", borderTop: "1px solid", borderBottom: "1px solid"}}></Box>
            <Box sx={{color: props.color, margin: "auto 10px"}}><Typography sx={{whiteSpace: "nowrap"}} variant={props.size}>{props.text}</Typography></Box>
            <Box sx={{color: props.color, height: "3px", width: "100%", margin: "auto 0px", borderTop: "1px solid", borderBottom: "1px solid"}}></Box>
        </Stack>
    )
    } else {
        return(
            <Box sx={{color: props.color, height: "3px", width: "100%", margin: "auto 0px", borderTop: "1px solid", borderBottom: "1px solid"}}></Box>
        )
    }
}