import './index.css';
import './App.css';
import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import MuiMarkdown from 'mui-markdown';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import MenuBar from './MenuBar'
import Container from '@mui/material/Container';
import DoubleLine from "./DoubleLine"
import CaveatFooter from "./CaveatFooter"
import { useTheme } from '@mui/material/styles';
import { CardActionArea, DialogContentText } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WebIcon from '@mui/icons-material/Web';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ReactGA from "react-ga4"
import ReactPixel from 'react-facebook-pixel';
import { ReactComponent as TikTokIcon } from './tiktok.svg'
import SvgIcon from '@mui/material/SvgIcon';
import ShowCard from './ShowCard'
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Performer(){


    let params = useParams();
    const [performer, setPerformer] = useState(null)
    const [events, setEvents] = useState(null)
    const [pastEvents, setPastEvents] = useState(null)

    const [displayState, setDisplayState] = useState("default")

    ReactGA.initialize([{trackingId: "G-LN3PNYXF7B"}]) 
 

    const fboptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init('336099940147449', fboptions);

    ReactPixel.pageView();

    useEffect(()=>{
        axios.get(`/api/performer/${params.atrec}`).then(response => {
          setPerformer(response.data.data)
          setEvents(response.data.events)
          setPastEvents(response.data.pastEvents)
          console.log(response.data)
        }).catch(error => {
          console.log(error)
        })
    
      }, [])

    useEffect(() => {
      if(performer){
        document.title = performer.fields.Name + " | Caveat"
        ReactGA.send({ hitType: "pageview" });
        ReactPixel.pageView();
      } else {
        document.title = 'Caveat Events';
      }
    }, [performer]);

    function Events(){

      if(! events){
        return("")
      }
      if(events.length == 0){
        return("")
      }
      const eventList = events.map((e) => 
        <Grid key={e.id} item xs={6} md={3}>
        <ShowCard short show={{'fields': e['fields']}} />
        </Grid>
      )

        return(

           <Box sx={{margin: "20px auto", maxWidth: "960px", padding: "10px 20px",backgroundColor: "#282828"}}>
              <DoubleLine text="UPCOMING SHOWS" color="white" size="h5" />
              <Box sx={{marginTop: "10px", marginBottom: "10px"}}>
                <Grid container spacing={2} justifyContent="center">
                    {eventList}
                </Grid>
              </Box>
            </Box>
      )
      }

    function PastEvents(){

      if(! pastEvents){
        return("")
      }
      if(pastEvents.length == 0){
        return("")
      }
      var eventSlice = pastEvents
      console.log(displayState)
      if(displayState=='default'){
        console.log(pastEvents.length)
        if(pastEvents.length > 4){
          eventSlice = pastEvents.slice(0, 4)
        }
      }
      console.log(eventSlice)
      const eventList = eventSlice.map((e) => 
        <Grid key={e.id} item xs={6} md={3}>
        <ShowCard short show={{'fields': e['fields']}} />
        </Grid>
      )

      return(

          <Box sx={{margin: "20px auto", maxWidth: "960px", padding: "10px 20px",backgroundColor: "#282828", opacity: "0.7", '&:hover': {opacity: "1"}}}>
            <DoubleLine text="PAST SHOWS" color="white" size="h5" />
            <Box sx={{marginTop: "10px", marginBottom: "10px"}}>
              <Grid container spacing={2} justifyContent="center">
                  {eventList}
              </Grid>
              {pastEvents.length > 4 ?
              <Button variant="contained" sx={{margin: "auto", marginTop:"10px", display: displayState==="all"? "none" : "block"}} onClick={() => {setDisplayState("all")}}>"SHOW ALL"</Button>
              : ""}
            </Box>
          </Box>
    )
    }

    if(! performer){return(
      <Box className="main">Loading...</Box>
    )}
    return(
        <Container className="container">
        <MenuBar active="Performers" />

        <Box className="main">
          <Grid container sx={{marginTop: "40px"}} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box component="img" src={performer.fields.s3Full} sx={{width: "100%", aspectRatio: "1", objectFit: "cover", objectPosition: ""}}>
                    </Box>
            </Grid> 
              <Grid item xs={12} sm={6} >

                    <Box sx={{textAlign: "center"}}><Typography  variant="showtitle" color="primary">{performer.fields.Name.toUpperCase()}</Typography></Box>
                        <Box sx={{marginTop: "20px", marginBottom: "20px"}}>{performer.fields.Bio}</Box>
                    {performer.fields.Instagram &&
                        <Link target="_blank" href={"https://instagram.com/"+performer.fields.Instagram}> <InstagramIcon /></Link>
                    }
                    {performer.fields.Facebook &&
                         <Link target="_blank" href={"https://facebook.com/"+performer.fields.Facebook}><FacebookIcon/></Link> }
                    {performer.fields.Twitter &&
                     <Link target="_blank" href={"https://twitter.com/"+performer.fields.Twitter}><TwitterIcon/></Link> }
                                   {performer.fields.TikTok &&
                     <Link target="_blank" href={"https://tiktok.com/@"+performer.fields.TikTok}><SvgIcon sx={{fontSize: "20px"}}><TikTokIcon /></SvgIcon></Link> }
                                   {performer.fields.Website &&
                     <Link target="_blank" href={performer.fields.Website}><WebIcon/></Link> }
            </Grid>
            <Grid item xs={12}>
                <Events />
                <PastEvents />
            </Grid>
          </Grid>
        </Box>

    <CaveatFooter />
    </Container>
    )
}