import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import Grid from '@mui/material/Grid';
import Carousel from 'react-material-ui-carousel'
import StarBorderPurple500RoundedIcon from '@mui/icons-material/StarBorderPurple500Rounded';
import ShowCard from "./ShowCard";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function ShowList() {

    const theme = useTheme();
    const [shows, setShows] = useState(null)

    useEffect(()=>{
        axios.get(`/api/events/upcoming`).then(response => {
          setShows(response.data)
        }).catch(error => {
          console.log(error)
        })
    
      }, [])

    var keylist=[0,3,6,9,12]
    var number = 3

    var smallcard = false
    if(useMediaQuery(theme.breakpoints.down('lg'))) { smallcard = true }
    if(useMediaQuery(theme.breakpoints.down('md'))){
        keylist=[0,2,4,6,8]
        number = 2
    } 
    if(useMediaQuery(theme.breakpoints.down('sm'))) {
        keylist=[0,1,2,3,4]
        number = 1
    }

    function ShowList(props){
        if(shows != null){
            let showList = shows.records
            showList.sort((a,b) => Date.parse(a.fields[ 'Event start date and time' ]) > Date.parse(b.fields[ 'Event start date and time' ]) ? 1 : -1);
            let upcomingShows = showList.slice(props.start,props.start+props.number)
            console.log(upcomingShows)
            const content = upcomingShows.map((show) =>
                <Grid key={show.id} justifyContent="center" container item xs={12} sm={6} md={4} >
                    <ShowCard wide={smallcard? false : true} show={show} />
                </Grid>
            ) 

            return(
                <Grid container justifyContent='center' spacing={2} className="show-cards">
                    {content}
                </Grid>)
        }
        else { return("Loading...") }

    }

    const carouselCards = keylist.map((key) => 
     <ShowList key={key} start={key} number={number}/>
    )


    return(
        <Carousel
            IndicatorIcon={<StarBorderPurple500RoundedIcon/>}
            interval={8000}
            sx = {{minHeight: "28rem", "marginRight": "20px"}}
        >
            {carouselCards}
        </Carousel>
    )

}

export default ShowList;