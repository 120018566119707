import './index.css';
import './App.css';
import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import MuiMarkdown from 'mui-markdown';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import MenuBar from './MenuBar'
import Container from '@mui/material/Container';
import DoubleLine from "./DoubleLine"
import CaveatFooter from "./CaveatFooter"
import { useTheme } from '@mui/material/styles';
import { CardActionArea, DialogContentText } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WebIcon from '@mui/icons-material/Web';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ReactGA from "react-ga4"
import ReactPixel from 'react-facebook-pixel';
import { ReactComponent as TikTokIcon } from './tiktok.svg'
import SvgIcon from '@mui/material/SvgIcon';
import ShowCard from './ShowCard';
import useMediaQuery from '@mui/material/useMediaQuery';
import useEventbrite from 'react-eventbrite-popup-checkout';
import {Helmet} from "react-helmet"




export default function ShowPage(){

    
    const modalButtonCheckout = useEventbrite({
        eventId: 'YOUR-EB-EVENT-ID',
        modal: true,
      });

    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    let params = useParams();
    let slug = params.event

    const styles = {
      active: {
        background: "#eed8f3",
        width: "100%",
        whiteSpace: "nowrap",
        padding: "10px"
      },
      other: {
        background: "#ffffff",
        width: "100%",
        whiteSpace: "nowrap",
        padding: "10px"
      },
      descriptionClosed: {
        height: "100px",
        overflow: "hidden"
      },
      descriptionOpen: {

      },
      showmore: {

      },

    }

    const [eventc, setEventc] = useState(null)
    const [series, setSeries] = useState(null)
    const [performers, setPerformers] = useState(null)
    const [showDesc, setShowDesc] = useState(false)
    const [performerOpen, setPerformerOpen] = useState(false)
    const [performer, setPerformer] = useState(null)
    const [modal, setModal] = useState(null)

    useEffect(()=>{
      ReactGA.initialize([{trackingId: "G-LN3PNYXF7B"}])  

      const fboptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init('336099940147449', fboptions);

      axios.get(`/api/event/${slug}`).then(response => {
        setEventc(response.data.event)
        setSeries(response.data.series.data)
        setPerformers(response.data.performers.data)
        if(slug != response.data.event.fields.slug){
          window.history.pushState("object or string", "Title", "/events/"+response.data.event.fields.slug);
        }
      }).catch(error => {
        console.log(error)
      })
    
      }, [])

      useEffect(() => {
        if(eventc){
          document.title = eventc.fields.Event + " | Caveat"
          ReactGA.send({ hitType: "pageview" });
          ReactPixel.pageView();
        } else {
          document.title = 'Caveat Events';
        }
      }, [eventc]);


      const handleClickOpen = (e) => {
        console.log(e)
        setPerformer(e)
        setPerformerOpen(true);
      };
    
      const handleClose = () => {
        setPerformerOpen(false);
      };

      function SeriesList(){
        if(series != null){
            let showList = series
            showList.sort((a,b) => Date.parse(a.fields[ 'Event start date and time' ]) > Date.parse(b.fields[ 'Event start date and time' ]) ? 1 : -1);
            const content = showList.map((show) =>
                <Grid key={show.id} justifyContent="center" container item xs={12}>
                  <Card sx={{width: "100%", marginBottom: "10px"}}>
                  <CardActionArea href={"/events/"+show.fields.slug}>
                    <Box sx={show.fields.slug === eventc.fields.slug ? styles.active : styles.other}>
                    <Stack direction="row" spacing={0} sx={{margin: "0px 20px"}}>

                      <Stack sx={{textAlign: "right"}}>
                    <Typography variant="h3">{show.fields.dateFormatted.toUpperCase()}</Typography>                   
                    <Typography variant="h5">{show.fields.StartTimeFormatted}</Typography>
                    </Stack>
                    <Box sx={{paddingLeft: "20px"}}>
                      <Typography variant="body" sx={{fontWeight: "700", whiteSpace: "normal"}}>{show.fields.Event}</Typography>
                    </Box>
                    </Stack>
                    </Box>
                    </CardActionArea>
                    </Card>
                </Grid>
            )  

            return(
                <Grid container justifyContent='center' spacing={0} className="show-cards" sx={{marginTop: "20px"}}>
                    {content}
                </Grid>)
        }
        else { return("Loading...") }
      }

      function Performers(){
        if(performers){
          if(performers.length > 0){
        const performerList = performers.map((p) => 
          <Grid key={p.id} item xs={6} sm={4} md={3}>
            <Card>
              <CardActionArea onClick={() => handleClickOpen(p)}>
              <CardMedia 
                component="img"
                height="100%"
                sx = {{aspectRatio: "1", objectPosition: ""}}
                image={p.fields.Image[0].url}
              />
              <Box sx={{margin: "10px 10px"}} spacing={0}>
              <Typography variant="body" sx={{fontWeight: "700"}}>
              {p.fields.Name}
              </Typography>
              <Box>
              <Typography variant="body" sx={{fontWeight: "400"}}>
              {p.fields.Title}
              </Typography>
              </Box>
              </Box>
              </CardActionArea>
            </Card>
          </Grid>
        )

        return(
          <Box sx={{marginBottom: "20px"}}>
          <DoubleLine text="PERFORMERS" size="h4" />
          <Grid container spacing={2} sx={{marginTop: "10px"}}>
            {performerList}
          </Grid>
          </Box>
        )
        }}
      }

      function PerformerDialogue(){
        if(performer){
          return(
            <Dialog
              open={performerOpen}
              onClose={handleClose}
              maxWidth="xs"
            >
              <a href={"/performer/"+performer.id}><Box component="img" src={performer.fields.Image[0].url} sx={{width: "100%", aspectRatio: "1", objectFit: "cover", objectPosition: "0 0"}}>
              </Box></a>
              <DialogTitle variant="h3">{performer.fields.Name}</DialogTitle>
              <DialogContent>
                <DialogContentText variant="body">
                  {performer.fields.Bio}
                </DialogContentText>
              {performer.fields.Instagram &&
                  <Link target="_blank" href={"https://instagram.com/"+performer.fields.Instagram}> <InstagramIcon /></Link>
              }
              {performer.fields.Facebook &&              
                   <Link target="_blank" href={"https://facebook.com/"+performer.fields.Facebook}><FacebookIcon/></Link> }
              {performer.fields.Twitter &&
               <Link target="_blank" href={"https://twitter.com/"+performer.fields.Twitter}><TwitterIcon/></Link> }
                             {performer.fields.TikTok &&
               <Link target="_blank" href={"https://tiktok.com/@"+performer.fields.TikTok}><SvgIcon sx={{fontSize: "20px"}}><TikTokIcon /></SvgIcon></Link> }
                             {performer.fields.Website &&
               <Link target="_blank" href={performer.fields.Website}><WebIcon/></Link> }
              </DialogContent>
            </Dialog>
          )
        }
      }

      function RelatedShows(){

        if(! eventc.fields.hasOwnProperty('related')){
          return("")
        }

        const related = JSON.parse(eventc.fields.related)
        console.log(related)

        const relatedList = related.map((e) => 
          <Grid key={e.event} item xs={6} md={3}>
          <ShowCard short show={{'fields': e}} />
          </Grid>
        )

        return(

           <Box sx={{margin: "20px auto", maxWidth: "960px", padding: "10px 20px", backgroundColor: "#282828"}}>
              <DoubleLine text="RELATED SHOWS" color="white" size="h5" />
              <Box sx={{marginTop: "10px", marginBottom: "10px"}}>
                <Grid container spacing={2}>
                    {relatedList}
                </Grid>
              </Box>
            </Box>
      )
      }

    function CustomModules(){

      var moduleS = eventc.fields.CustomModules[0].slice(0,eventc.fields.CustomModules[0].length)
      console.log(moduleS)
      var module = JSON.parse(moduleS)
      console.log(module.SectionTitle)

      return( 

        <Box sx={{marginTop: "20px", marginBottom: "20px", backgroundColor: "#e3e3e3", padding: "10px 20px 20px"}}>
          <DoubleLine color="black" size="h5" text={module.SectionTitle.toUpperCase()} />

          <Box sx={{marginTop: "10px", color: "black"}}>
            <Box sx={{marginBottom: "10px"}}>
              <Typography variant="body" dangerouslySetInnerHTML={{__html: module.Copy}}></Typography>
            </Box>
            <Button href={module.ButtonLink} variant="contained"><Typography variant="h5">{module.ButtonText}</Typography></Button>
          </Box>
        </Box>
      )
    }

    function TicketButton(){                  
    
      if(eventc.fields[ 'Fever URL' ].includes('eventbrite')){
        console.log(eventc.fields[ 'EventbriteID' ])
      return(
        <Box>
        <Button sx={{width: "100%", margin: "10px 0", backgroundColor: eventc.fields.hasOwnProperty('Sold out') ? eventc.fields.hasOwnProperty('Livestream') ? "#cc31ca" : "#cc31ca" : "" }} variant="contained" id="example-widget-trigger" type="button"><Typography variant="h5">        { eventc.fields.hasOwnProperty('Sold out') ?

eventc.fields.hasOwnProperty('Livestream')?
                          "SOLD OUT"
                          :
                          "SOLD OUT"
                          :
                          "TICKETS"
                      }</Typography></Button>
        <Helmet>
        <script src="https://www.eventbrite.com/static/widgets/eb_widgets.js"></script>
        <script>
    {`var exampleCallback = function () {
    console.log('Order complete!');
    };

    window.EBWidgets.createWidget({
    widgetType: 'checkout',
    eventId: '${eventc.fields[ 'EventbriteID' ]}',
    modal: true,
    modalTriggerElementId: 'example-widget-trigger',
    onOrderComplete: exampleCallback,
    });`}
    </script>
        </Helmet>
        </Box>
      )
  }
  else{
      return(
        <Box>
        <Button sx={{width: "100%", margin: "10px 0", backgroundColor: eventc.fields.hasOwnProperty('Sold out') ? eventc.fields.hasOwnProperty('Livestream') ? "#cc31ca" : "#cc31ca" : "" }} variant="contained" href={eventc.fields['UseFeverURLFormula'] ? "https://"+eventc.fields[ 'Fever URL' ] : eventc.fields[ 'Stellar URL' ]} target="_blank"><Typography variant="h5">
        { eventc.fields.hasOwnProperty('Sold out') ?

eventc.fields.hasOwnProperty('Livestream')?
                          "SOLD OUT"
                          :
                          "SOLD OUT"
                          :
                          "TICKETS"
                      }
          </Typography></Button>
          </Box>
      )
    }
  }
  
    return(

        <Container className="container">

            <MenuBar />

            { eventc !== null ?
            <Box className="main">


            <Grid container spacing={2} sx={{marginTop: "30px"}}>
              <Grid item xs={12}>
              <Box sx={{width: "100%", borderRadius: "10px", margin: "auto", minHeight: {xs: "200px", sm: "469px", lg: "562px"}}}>
                    <img style={{ objectFit: "cover", objectPosition: "50% 0",borderRadius: "10px"}} src={eventc.fields.s3Full} />
                  </Box>

              </Grid>
              <Grid item xs={12} md={4}>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12}>
                <Box sx={{padding: {xs: "0 20px", md: "0 0px"}}}>

                  <DoubleLine text="SHOW INFO" size="h3"/>
                  <Box sx={{minHeight: "0px"}}>
                      <Typography variant="body" dangerouslySetInnerHTML={{__html: eventc.fields.CustomShowInfo}}></Typography>
                  </Box>
                
                  {        <Box>
        <Button sx={{width: "100%", margin: "10px 0", backgroundColor: eventc.fields.hasOwnProperty('Sold out') ? eventc.fields.hasOwnProperty('Livestream') ? "#cc31ca" : "#cc31ca" : "" }} variant="contained" href={eventc.fields['UseFeverURLFormula'] ? "https://"+eventc.fields[ 'Fever URL' ] : eventc.fields[ 'Stellar URL' ]} target="_blank"><Typography variant="h5">
        { eventc.fields.hasOwnProperty('Sold out') ?

eventc.fields.hasOwnProperty('Livestream')?
                          "SOLD OUT"
                          :
                          "SOLD OUT"
                          :
                          "TICKETS"
                      }
          </Typography></Button>
          </Box>}

                  { eventc.fields.hasOwnProperty('Livestream') ?
                    <Button sx={{width: "100%", margin: "10px 0"}} variant="contained" href={eventc.fields[ 'Stellar URL' ]} target="_blank"><Typography variant="h5">
                                    LIVESTREAM
                    </Typography></Button>
                    : ""
                  }
                </Box>
                </Grid>
                <Grid item sx={{marginTop: "20px"}}>
                {isMobile ?  "" : <DoubleLine text="ALL EVENTS" size="h3"/>}
                  {isMobile ?  "" : <SeriesList />}
                </Grid>
              </Grid>
              <Grid item xs={12} md={8} >
              <Box sx={{padding: "0 20px"}}>
                
              <DoubleLine text="ABOUT THIS SHOW" size="h3"/>
              <Box sx={{margin: "20px auto 0px", textAlign: "center"}}>
                <Typography variant="showtitle" color={theme.palette.primary.main}>{eventc.fields.Event.toUpperCase()}</Typography>
                </Box>
                <Box sx={{margin: "0px auto 20px", textAlign: "center"}}>
                <Typography variant="showdate" color={theme.palette.primary.main}>{eventc.fields.dateFormatted.toUpperCase()}, {eventc.fields.StartTimeFormatted}</Typography>
                </Box>

                {/* <Box sx={showDesc ? styles.descriptionOpen : styles.descriptionClosed}> */}
                <Box sx={styles.descriptionOpen}>
                <Typography variant='body' dangerouslySetInnerHTML={{ __html: eventc.fields.description}}></Typography>
                </Box>
                {/* <Button variant="text" sx={styles.showmore} onClick={() => setShowDesc(!showDesc)}>{showDesc ? "SHOW LESS" : "SHOW MORE"}</Button> */}
                
                <Box>
                  {eventc.fields.Instagram &&
                    <Link  target="_blank" href={"https://instagram.com/"+eventc.fields.Instagram}> <InstagramIcon /></Link>
                                }
                                {eventc.fields.Facebook &&
                     <Link target="_blank" href={"https://facebook.com/"+eventc.fields.Facebook} ><FacebookIcon/></Link> }
                                {eventc.fields.Twitter &&
                                 <Link target="_blank" href={"https://twitter.com/"+eventc.fields.Twitter} ><TwitterIcon/></Link> }
                                {eventc.fields.TikTok &&
                                 <Link target="_blank" href={"https://tiktok.com/"+eventc.fields.TikTok}><SvgIcon sx={{fontSize: "20px"}}><TikTokIcon /></SvgIcon></Link> }
                                {eventc.fields.Website &&
                                 <Link target="_blank" href={eventc.fields.Website} ><WebIcon/></Link> }
                </Box>
                {eventc.fields.hasOwnProperty('CustomModules') ? <CustomModules /> : ""}

                <Performers />
                <PerformerDialogue />

              </Box>
              </Grid>
            </Grid>
            {isMobile ? <DoubleLine text="ALL EVENTS" size="h3"/> : ""}
            {isMobile ? <SeriesList /> : ""}
            <RelatedShows/>
            </Box>
            :
            ""
            }
        {eventc ? <CaveatFooter /> : ""}
        </Container>

    )
}